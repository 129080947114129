@import "@/styles/globals.scss";






















































































































































.cancel-auto-renew-route {
  .actions {
    border-top: var(--divider);
    margin-top: 24px;
    padding-top: 24px;
  }
}
