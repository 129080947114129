@import "@/styles/globals.scss";



















































































































































































.citypass-coupons {
  margin-top: 32px;

  @include mobileExperience {
    margin-top: 24px;
  }

  .ticket-groups {
    margin-top: 32px;
  }

  .ticket-type-description-wrapper {
    margin-bottom: 8px;
  }

  .coupon {
    margin-bottom: 8px;
  }
}
