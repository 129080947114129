@import "@/styles/globals.scss";































.site-content {
  details.controls {
    background-color: #efefef;
    padding: 20px;
    border-radius: 4px;
    margin: 16px 0;

    .dark-theme & {
      background-color: rgba(255, 255, 255, 0.2);
    }

    summary {
      width: 50%;
      background-color: rgba(0, 0, 0, 0.075);
    }

    label.checkbox {
      margin-bottom: 8px;
    }
  }

  .story-viewer {
    width: 100%;
  }
}
