@import "@/styles/globals.scss";
























svg.trash-icon {
  stroke: var(--secondary-color);

  &:hover {
    stroke: var(--primary-color);
  }
}
