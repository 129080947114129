@import "@/styles/globals.scss";

























































































.email-submit-form {
  form,
  .form-input.email {
    margin-bottom: 16px;
  }
  .navigate-back {
    margin: 0;
  }
}
