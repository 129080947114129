@import "@/styles/globals.scss";



















































































.mobile-cart {
  display: none;
  max-height: 90%;

  @include mobileExperience {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .modal-header {
    h1 {
      font-size: 26px;
      margin: 0;
      flex-grow: 1;
      color: var(--dialog-header-text-color);
    }
  }

  .cart-widget {
    max-height: calc(
      100vh - var(--mobile-modal-top-offset) - var(--mobile-cart-footer-height) - var(--mobile-cart-header-height)
    );
    overflow: auto;
    margin-top: 0;

    section.cart {
      margin-top: 0;
      border-radius: 0;
      border: 0;
    }

    .ticket-wrapper,
    .apply-promo-codes,
    .total-prices {
      padding-left: 16px;
      padding-right: 16px;
    }

    .content {
      .add-promo-form {
        padding-left: 0;
        padding-right: 0;
      }

      .add-promo-button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .modal-body {
    padding: 0;
  }

  .mobile-cart-widget {
    overflow: auto;
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.mobile-footer.mobile-cart-footer {
  position: relative;
  z-index: 1;
  padding: 16px 16px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);

  .total {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .actions {
    gap: 12px;

    button {
      display: block;
      text-align: center;
      height: 48px;
      padding: 0;
    }
  }
}
