@import "@/styles/globals.scss";

















































.modal {
  .terms-and-conditions-dialog {
    .terms-and-conditions-content-wrapper {
      position: relative;
    }

    .terms-and-conditions-content {
      max-height: 50vh;
      overflow: auto;
      border: 1px solid var(--light-gray);
      padding: 16px;
      margin-bottom: 24px;

      .terms-and-conditions-html > :first-child {
        margin-top: 0;
      }

      @include mobileExperience {
        margin-top: 0;
        padding: 0 0;
        border: 0;
      }
    }

    .actions {
      @include mobileExperience {
        flex-direction: row;

        button {
          flex-grow: 1;
        }
      }
    }
  }
}
