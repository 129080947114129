@import "@/styles/globals.scss";





























svg.info-icon {
  stroke: white;
}
