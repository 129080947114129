@import "@/styles/globals.scss";





















svg.chevron-right {
  stroke: var(--dark-gray);
}
