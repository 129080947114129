@import "@/styles/globals.scss";
















svg.circle-icon {
  stroke: #BFC1CA;
}
