@import "@/styles/globals.scss";











































































































































































































































































































































































































































$selected-color: #e6cc46;

#seat-hover {
  pointer-events: none;
}

.seat-map,
.map-control {
  min-height: 0;
}

.map-control {
  border: 1px solid var(--light-gray);
  background-color: white;
  position: relative;

  @include mobileExperience {
    display: flex;
    flex-direction: column;
  }

  .confirm-selection-wrapper,
  .seat-key {
    border-top: 1px solid var(--light-gray);
    padding: 8px;
  }

  .confirm-selection-wrapper {
    .button {
      width: 100%;
    }
  }

  .seat-key {
    margin: 0;
    display: flex;
    justify-content: center;

    li {
      margin: 0 12px;
      padding: 0;
      list-style: none;
      font-size: 14px;
      line-height: 1;

      svg,
      img {
        display: inline-block;
        vertical-align: middle;
      }

      svg.unavailable {
        circle {
          fill: var(--light-gray);
        }
      }

      img.booked {
        opacity: 0.25;
      }

      svg.selected {
        circle {
          fill: $selected-color;
          stroke-width: 2px;
          stroke: #222222;
        }
      }
    }
  }

  svg.map {
    width: 100%;
    height: auto;
    display: block;
    flex-grow: 1;

    image.available,
    circle#seat-hover,
    circle.selected {
      cursor: pointer;
    }

    image {
      border-radius: 50%;

      &.booked {
        opacity: 0.25;
      }
    }
  }

  .zoom-controls {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .zoom-button {
    background-color: white;
    border: 2px solid var(--light-gray);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    font-size: 24px;
    line-height: 1;
    height: 34px;
    width: 34px;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    float: left;
    padding: 0;

    svg,
    svg:hover {
      fill: var(--medium-gray);
    }

    &[disabled] svg {
      opacity: 0.25;
    }

    &:not([disabled]):hover {
      background-color: #efefef;
    }
  }
}
