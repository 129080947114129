@import "@/styles/globals.scss";




































































































































.single-or-any {
  @include desktopExperience {
    display: grid;
    align-items: center;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  button {
    padding-top: 22px;
    padding-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include mobileExperience {
      margin-bottom: 16px;
    }
  }
}

.selected-date-time {
  @include desktopExperience {
    max-width: calc(50% - 6px);
  }
}
