@import "@/styles/globals.scss";





























































































































.upsell {
  label.radio-item.form-input.selectable.ticket-type {
    background-color: var(--very-light-gray);
    box-shadow: 0 1px 0 #d5d5d5;
  }
}
