@import "@/styles/globals.scss";





















svg.dash-icon {
  stroke: white;
}
