@import "@/styles/globals.scss";



























































































































































// Unscoped so that markup with similar components can use these styles. E.g. <TicketTypeRadioItem > .
.radio-group {
  .radio-item {
    margin-bottom: 16px;

    &.selectable {
      @include selectable;
    }

    &.disabled {
      opacity: 0.2;
    }

    // TODO Add support for IE11?
    display: grid;
    grid-template-columns: 32px 1fr;

    > .input {
      display: flex;
      width: 32px;
      grid-column: 1;
      grid-row: 1 / 3;
    }

    .label {
      grid-column: 2;
      grid-row: 1;
    }

    .description {
      grid-column: 2;
      grid-row: 2;
    }

    .show-benefits {
      grid-column: 2;
      grid-row: 3;
    }

    .label {
      margin: 0;
      line-height: 24px;
      font-weight: bold;
    }

    .prices {
      display: flex;

      .price-wrapper {
        margin-left: 10px;
      }

      .original-price .price {
        text-decoration: line-through;
        opacity: 0.5;
        font-size: 16px;
        font-weight: 400;
        margin-top: 4px;
      }
    }
  }
}
