@import "@/styles/globals.scss";





















































































.event-listing-route {
  .aside {
    @include mobileExperience {
      display: none !important;
    }
  }
}
