// This file must only include mixins, placeholders or variables as executable styles will be duplicated
// because of prependData in vue.config.js.

@mixin selectable {
  display: inline-block;
  margin: 0 8px 8px 0;
  line-height: 46px;
  height: 48px;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--light-gray);

  input[type='radio'] {
    display: none;
  }

  > .label {
    font-weight: normal;
    line-height: inherit;
  }

  &[disabled] {
    text-decoration: line-through;
    &[title] {
      cursor: help;
    }
  }

  &:not([disabled], .selected) {
    cursor: pointer;
  }

  &.selected {
    color: white;
  }
}
