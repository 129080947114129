@import "@/styles/globals.scss";































































































































































































































































.route.completed-order-route {
  /* @see https://superuser.com/questions/530317/how-to-prevent-chrome-from-blurring-small-images-when-zoomed-in */
  .qr-code img {
    image-rendering: optimizeSpeed; /*                     */
    image-rendering: -moz-crisp-edges; /* Firefox             */
    image-rendering: -o-crisp-edges; /* Opera               */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
    image-rendering: pixelated; /* Chrome as of 2019   */
    image-rendering: optimize-contrast; /* CSS3 Proposed       */
    -ms-interpolation-mode: nearest-neighbor; /* IE8+                */
    width: 100%;
    @include mediumAndLarge {
      width: 200px;
      display: inline-flex;
    }
  }

  .booking {
    margin: 16px 0;
    border-left: 3px solid var(--secondary-color);
    padding-left: 8px;

    h4,
    p {
      margin: 0;
    }
  }
}

.complete {
  .loader {
    margin-top: 100px;
  }
}
