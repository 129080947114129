@import "@/styles/globals.scss";



























































































































































































































































































































































































































































































































































































































































































































































































































































































































.reserve-date-first-form > .survey {
  margin-bottom: 24px;
}

.dynamic-messages {
  margin-bottom: 24px;
}
