@import "@/styles/globals.scss";















































































.radio-item-ticket-type {
  label.radio-item {
    display: flex;

    input[type='radio'] {
      margin-right: 0.5em;
    }

    .label {
      margin: 0;
    }
  }

  .custom-value {
    display: flex;
    align-items: flex-start;
    margin-left: calc(32px);

    .currency-symbol {
      position: relative;
      top: 20px;
    }

    .form-input {
      width: max-content;
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
      width: auto;
    }
  }
}
