@import "@/styles/globals.scss";


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.total-outstanding h3 {
  display: flex;
  .text {
    flex-grow: 1;
  }
}

.checkout-heading {
  @include mobileExperience {
    display: none;
  }
}

.mobile-checkout-heading {
  display: none;
  margin: 0;
  padding: 20px 70px;
  border-bottom: 1px solid #dde0e5;
  text-align: center;
  margin-bottom: 24px;

  @include mobileExperience {
    display: block;
  }
}

.checkout-route {
  > .aside {
    @include mobileExperience {
      display: none !important;
    }
  }

  .main > .actions {
    @include mobileExperience {
      display: none !important;
    }
  }
}
