@import "@/styles/globals.scss";





























































.dialog.delete-cart-or-checkout-dialog {
  text-align: center;

  p {
    margin-top: 0;
  }

  .actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-right: -16px;

    @include mobileExperience {
      flex-direction: column;
    }

    > * {
      width: auto;
      flex-grow: 1;
      margin-right: 16px;
    }

    button.cancel {
      text-align: center;
    }
  }
}
