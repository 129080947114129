@import "@/styles/globals.scss";

































































































































































































































































































































































































.cart {
  .ticket-wrapper,
  .ticket > header,
  .ticket .content,
  .ticket .content .ticket-types > .ticket-type,
  .line-item:not(.ticket) {
    display: flex;
    gap: 16px;
  }

  .ticket-wrapper > .ticket,
  .ticket > header > h4,
  .ticket .content .ticket-types,
  .ticket .content .ticket-types > .ticket-type .type-count,
  .line-item:not(.ticket) > :first-child {
    flex: 1;
  }

  @include large {
    section.cart {
      display: grid;
      grid-template-rows: auto 1fr;

      > .content {
        min-height: 0;
        display: grid;

        .tickets {
          overflow-y: auto;
          min-height: 60px;
        }
      }
    }
  }

  .ticket {
    position: relative;

    header {
      gap: 0;

      .cart-item-thumbnail {
        margin-right: 12px;
      }

      .header-content {
        margin-right: 12px;
        flex-grow: 1;
      }
    }

    .ticket-date {
      font-size: 13px;
      margin-top: 8px;
    }

    .cart-item-thumbnail {
      flex-shrink: 0;
      margin-bottom: 0;
      width: 60px;
      height: 51px;

      img {
        width: auto;
        object-fit: cover;
      }
    }
  }

  .ticket-wrapper {
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid var(--light-gray);
      padding-bottom: 16px;
    }

    .remove-cart-item-overlay {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 1px;
      width: 100%;
      z-index: 1;
    }

    .actions {
      flex-basis: 24px;

      button.remove {
        border: 0 none;

        // Expand the button's invisible clickable area.
        margin: -8px;
        padding: 8px;

        font-size: 20px;
        line-height: 20px;

        svg {
          height: 17px;
        }
      }
    }
  }

  .price-wrapper {
    white-space: nowrap;
    text-align: right;
  }

  $smaller: calc(1rem - 2px);

  .line-item {
    h3,
    h4 {
      margin: 0;
    }

    .content-wrapper {
      font-size: $smaller;
    }
  }

  .total-prices {
    padding-top: 16px;
    padding-bottom: 20px;

    :not(.total-due) > h3 {
      font-weight: normal;
    }

    ul.discounts-content li,
    .gift-card .balance {
      font-size: $smaller;
      padding-left: 16px;
    }

    ul.discounts-content {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .gift-card h4 {
      font-weight: normal;
      font-size: inherit;
    }

    .total-due {
      font-weight: bold;
      margin-top: 16px;
      border-top: 1px solid var(--light-gray);
      padding-top: 16px;
    }
  }

  .pay-button-wrapper[class*='wallet-'] {
    padding: 16px 16px;
  }

  div.primary.purchase.branded-button {
    position: relative;
  }

  button.primary.purchase {
    width: 100%;
    min-height: 56px;
    font-size: calc(1rem + 2px);

    // Fixes a weird layout bug in Safari.
    // @see https://trello.com/c/IUaJUzYe
    margin-top: 0;
    margin-bottom: 0;
  }

  .dark .content .tickets {
    &::-webkit-scrollbar {
      width: 16px;
      height: 3px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #000;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #666;
      border: solid 4px black;
      border-radius: 8px;
    }
  }
}
