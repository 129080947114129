@import "@/styles/globals.scss";





















































































@include small {
  .sentence {
    display: none;
  }
}

@include mediumAndLarge {
  .short-labels {
    display: none;
  }
}

.time-filters {
  display: flex;
}
