@import "@/styles/globals.scss";


























































.secondary.cart-button {
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;

  .count {
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 11px;
    min-width: 16px;
    font-weight: 600;
  }
}
