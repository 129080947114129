@import "@/styles/globals.scss";



















































































































































































































































































































































.reserve-single-event-form {
  .message {
    margin-top: 32px;

    @include mobileExperience {
      margin-top: 24px;
    }
  }
}
