@import "@/styles/globals.scss";





































































































































































































































































.select-seats {
  max-width: 755px;
  margin-top: 32px;

  .button {
    text-align: center;

    &.find-seats {
      width: 100%;
    }

    &.find-seats,
    &.change-seats {
      @include mobileExperience {
        &.mobile {
          &.processing {
            display: flex;
          }
        }
      }
    }
  }

  .selected-seats {
    margin-top: 40px;

    .selected-seats-list {
      list-style: none;
      margin: 16px 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .selected-seat {
        background-color: var(--very-light-gray);
        border-radius: 4px;
        flex-basis: calc(50% - 6px);
        text-align: center;
        padding: 12px;

        @include mediumAndLarge {
          flex-basis: calc(25% - 6px);
        }

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100%;
          margin-right: 6px;
          vertical-align: middle;
          display: inline-block;
        }

        @for $i from 1 through 20 {
          &.ticket-group-#{$i}-color:before {
            background-color: var(--ticket-group-#{$i}-color);
          }
        }
      }
    }
  }
}

.seat-selector {
  div.message.seat-select {
    border-radius: 0;
    border: none;
    margin-bottom: 12px;

    .message-content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;

      .button {
        margin-left: 12px;
      }
    }

    svg {
      border: 2px solid #4694e6;
      background-color: transparent;
      stroke: #4694e6;
    }
  }

  .seat-filters {
    background-color: white;
    border: 1px solid var(--light-gray);
    border-bottom: none;
    padding: 16px;

    .seat-filter-label {
      font-size: 14px;
      margin-right: 8px;
    }

    .seat-filter {
      border: 2px solid var(--light-gray);
      border-radius: 30px;
      padding: 8px 12px;
      background-color: white;
      font-family: inherit;
      margin-right: 8px;

      &.selected.all {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: white;
      }

      &:not(.all) {
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          background-color: black;
          border-radius: 100%;
          display: inline-block;
          margin-right: 2px;
        }
      }

      &:hover {
        border-color: #cbcbcb;
      }

      @for $i from 1 through 20 {
        &.ticket-group-#{$i}-color {
          &:before {
            background-color: var(--ticket-group-#{$i}-color);
          }

          &.selected {
            background-color: var(--ticket-group-#{$i}-color);
            border-color: var(--ticket-group-#{$i}-color);
            color: white;

            &:before {
              background-color: white;
            }
          }
        }
      }
    }
  }

  .confirm-selection-wrapper {
    @include large {
      margin-top: 16px;
    }

    button {
      width: 100%;
    }
  }

  .close-seat-modal {
    display: none;
  }
}

.seat-map-modal:not(:empty) {
  position: fixed;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;

  .seat-map-header {
    padding: 20px;
    text-align: center;
    position: relative;
    border-bottom: 1px solid var(--light-gray);

    @include mobileExperience {
      h3 {
        margin: 0;
        font-size: 16px;
      }
    }

    .back-button {
      position: absolute;
      left: 16px;
      top: 50%;
      width: 32px;
      height: 32px;
      transform: translateY(-50%);
    }
  }

  .seat-selector {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    div.message.seat-select {
      flex-grow: 0;
      margin-bottom: 0;

      button {
        display: none;
      }
    }

    .seat-filters {
      border-left: none;
      border-right: none;
    }

    .seat-map {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .map-control {
        flex-grow: 1;
        border-left: none;
        border-right: none;
      }

      .seat-key {
        li {
          margin: 0 6px;
          white-space: nowrap;

          svg,
          img {
            width: 8px;
            height: 8px;
          }
        }
      }
    }

    .confirm-selection-wrapper {
      width: 100%;
      padding: 16px;
      background-color: white;
    }
  }
}

.mobile-footer {
  .button.find-seats {
    height: 48px;
    width: 100%;
  }
}
