@import "@/styles/globals.scss";
















svg.minus-icon {
  fill: var(--primary-color);

  &:hover {
    fill: var(--secondary-color);
  }
}
