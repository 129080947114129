@import "@/styles/globals.scss";





































































































































.admit-details-fields {
  margin-top: 32px;

  @include mobileExperience {
    margin-top: 24px;
  }
}
