@import "@/styles/globals.scss";











































































































































































































































































































































































.reschedule-experience {
  margin-bottom: 32px;

  .reschedule-content {
    max-width: 690px;
  }

  div.message.error {
    margin-top: 32px;
  }

  .reschedule-details {
    h1 {
      line-height: 1;
    }

    p {
      margin: 0;
    }
  }

  .selection {
    .loader {
      margin-top: 16px;
    }
  }

  .select-date {
    margin-top: 40px;

    h3 {
      margin-top: 0;
    }
  }

  .select-session-wrapper {
    margin-top: 40px;
  }

  .radio-group .radio-item.selectable {
    padding-left: 0;
    padding-right: 0;
  }

  #datepicker {
    svg {
      margin-left: 16px;
    }
  }

  @include smallAndMedium {
    .sticky-footer {
      .navigate-back {
        width: 25%;
        text-align: left;
      }

      button.primary {
        width: 40%;
      }
    }
  }
}
