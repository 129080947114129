@import "@/styles/globals.scss";
















svg.chevron-down {
  stroke: var(--dark-gray);
  transform: rotate(180deg);
}
