@import "@/styles/globals.scss";

















































































.expiry-timer {
  padding-top: 16px;

  div.message.expires-in {
    margin: 0 16px;
    padding: 7px 16px 7px 10px;
    border: none;
    font-size: 15px;

    svg {
      margin: 0 3px 0 0;
    }

    > span {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .time {
      margin-left: auto;
      white-space: nowrap;
    }
  }
}
