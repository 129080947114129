@import "@/styles/globals.scss";



































svg.cart-icon {
  stroke: var(--secondary-color);
}
