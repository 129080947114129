@import "@/styles/globals.scss";





















svg.user-circle-icon {
  stroke: white;
}
