@import "@/styles/globals.scss";



























































































































































































































































































































































































































































































.select-tickets {
  // Layout of each type's selector.
  .ticket-type,
  .ticket-type-headers {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ticket-type-description-wrapper {
      margin-bottom: 6px;

      .ticket-type-description {
        padding-right: 16px;
        flex-grow: 1;
        word-break: break-word;
      }

      @include large {
        display: flex;
        flex-grow: 1;
        margin-bottom: 0;
      }
    }

    .price-container {
      padding-right: 16px;

      @include large {
        flex-basis: 45%;
        align-self: center;
        flex-shrink: 0;
        text-align: right;
      }

      @include mobileExperience {
        margin-top: 8px;
      }

      .fees {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &.fixed-price {
      // Applies to prices and 'free'
      .price-wrapper {
        font-weight: bold;
      }
    }

    &.flexible-price {
      .price-container {
        max-width: 55%;
      }

      @include smallAndMedium {
        flex-wrap: wrap;

        .ticket-type-description-wrapper {
          width: 100%;
        }
      }

      @include large {
        .price-container {
          flex-basis: 40%;

          .form-input {
            justify-content: flex-end;
          }
        }
      }

      .form-input {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .label {
          font-weight: bold;
          font-size: 16px;
          margin-right: 4px;

          @include large() {
            margin-right: 8px;
          }
        }

        input {
          width: 75px;
          margin-right: 6px;

          @include large() {
            margin-right: 12px;
          }
        }

        input[type='number'] {
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .message.invalid {
          display: none;
        }
      }

      span.message.invalid {
        padding: 0;
        border: none;
        background-color: transparent;
        margin-bottom: 0;
        margin-left: 18px;
        margin-top: 12px;

        &:before {
          display: none;
        }
      }

      .range {
        font-size: 11px;
        line-height: 1.5;

        .price-wrapper {
          display: inline-block;
        }
      }
    }
  }

  .ticket-type-headers {
    display: none;

    @include large {
      display: flex;

      .ticket-type-header-wrapper {
        flex-grow: 1;
        display: flex;

        .ticket-type-header {
          flex-grow: 1;
        }

        .price-header {
          flex-basis: 45%;
          text-align: right;
          padding-right: 16px;
        }
      }

      .quantity-header {
        flex-basis: 146px;
        text-align: right;
      }
    }
  }

  .ticket-group.is-seated {
    .ticket-group-description-wrapper {
      position: relative;

      &:before {
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        position: absolute;
        background-color: black;
        border-radius: 100%;
        top: 8px;
        right: 0;
      }

      @for $i from 1 through 20 {
        &.ticket-group-#{$i}-color {
          &:before {
            background-color: var(--ticket-group-#{$i}-color);
          }
        }
      }
    }
  }
}
