@import "@/styles/globals.scss";












































































































.apply-promo-codes {
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);

  button.add-promo-button {
    text-align: left;
    width: 100%;
  }

  .joint-input-button > .wrapper {
    display: flex;

    .promo-number-field {
      width: 5em;
      flex-grow: 1;
    }

    button {
      border: 0 none;
      background: none;
      width: auto;
      padding-left: 16px;
      padding-right: 16px;

      &[type='reset'] {
        display: flex;
        align-items: center;
        padding-left: 3px;
        padding-right: 3px;
        margin-left: 13px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
