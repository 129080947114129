@import "@/styles/globals.scss";




















































































































.complete {
  .loader {
    margin-top: 100px;
  }
}
