@import "@/styles/globals.scss";


























svg.gift-aid-icon {
  fill: #000000;
}
