@import "@/styles/globals.scss";




































div.gift-aid-checkbox {
  padding: 24px;
  background-color: #f9f5e4;

  input:not(:checked) {
    background-color: white;
  }
}
