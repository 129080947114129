@import "@/styles/globals.scss";

















svg.exclamation-icon {
  stroke: white;
}
