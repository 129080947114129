@import "@/styles/globals.scss";



























































































































































































































































































































































































































































.content-detail-page {
  .main.loader {
    padding: 30px 0;
  }

  .event-heading,
  .event {
    @include mobileExperience {
      display: none;
    }
  }

  .mobile-event {
    display: none;

    &-content {
      box-shadow: 0 -6px 6px rgb(0 0 0 / 5%);
      padding-top: 16px;
      margin-bottom: 24px;
    }

    &.no-image {
      .mobile-content-wrapper {
        padding-top: 80px;
      }
    }

    @include mobileExperience {
      display: block;
    }

    > figure {
      margin: 0;
      display: flex;
      justify-content: center;
      overflow: hidden;
      height: 225px;

      img {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
        will-change: top;
        background-color: #dddddd;
      }

      &.no-cropping {
        img {
          object-fit: contain;
        }
      }
    }
  }

  .mobile-content-wrapper {
    position: relative;

    h1 {
      margin-top: 0;
      margin-bottom: 8px;
      font-size: 26px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 600;
    }

    .description-link {
      @include mobileExperience {
        margin: 4px 0 0 0;
      }

      > a {
        display: inline-flex;
        align-items: center;
      }

      .svg-icon {
        margin-left: 6px;
        stroke: var(--primary-color);
      }
    }
  }

  .aside {
    @include mobileExperience {
      // Force this to be hidden as it shouldn't visible in any cases
      display: none !important;
    }
  }

  .main form .actions,
  .main .navigate-back {
    @include mobileExperience {
      display: none;
    }
  }

  .sidebar-promo,
  .sidebar-membership-promo {
    margin-top: 40px;

    @include mobileExperience {
      margin-top: 32px;
    }
  }
}
