@import "@/styles/globals.scss";


















svg.clock-icon {
  background: none;
}
