@import "@/styles/globals.scss";
































.cart-expiry-modal {
  p:first-child {
    margin-top: 0;
  }

  .actions {
    button {
      @include mobileExperience {
        width: 100%;
      }
    }
  }
}
