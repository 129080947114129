@import "@/styles/globals.scss";
































































































details.member-menu {
  .member-menu-icon {
    .close {
      display: none;
    }
  }

  &[open] {
    .member-menu-icon {
      .close {
        display: block;
      }

      .open {
        display: none;
      }
    }
  }
}
