@import "@/styles/globals.scss";






























































































































.home-route {
  // Three columns.
  .options {
    display: flex;

    .first {
      order: 1;
    }

    .second {
      order: 2;
    }

    .third {
      order: 3;
    }

    @include small {
      flex-direction: column;
    }

    @include smallAndMedium {
      > section {
        margin-bottom: 32px;
        border-bottom: 1px solid var(--light-gray);
        padding-bottom: 32px;
      }
    }

    @include mediumOnly {
      flex-wrap: wrap;
      justify-content: space-between;
      > section {
        width: calc(50% - 16px);
      }
    }

    @mixin growSecondElement($direction: column) {
      display: flex;
      flex-direction: $direction;

      > :nth-child(2) {
        flex-grow: 1;
      }
    }

    @include mediumAndLarge {
      // Ensures the "Forgot" link is the only element that grows to fill empty space in the column.
      > section {
        @include growSecondElement;
        &.login > form.login {
          @include growSecondElement;
          > .actions {
            @include growSecondElement(column-reverse);
            > button {
              flex-basis: auto !important;
            }
          }
        }
      }
    }

    @include large {
      > section.first {
        width: calc((100% - 128px) / 3);
      }

      > section:not(.first) {
        width: calc((100% - 128px) / 3 + 32px);
        margin-left: 32px;
        border-left: 1px solid var(--light-gray);
        padding-left: 32px;
      }
    }

    > section {
      > h2 {
        margin-top: 0;
      }

      // Content-width buttons.
      a.button,
      button {
        max-width: max-content;
      }
    }
  }

  form.login > .actions {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    button.primary {
      margin-top: 32px;
      margin-bottom: 0;
    }
  }

  // Footer.
  .resend-tickets {
    text-align: center;
  }
}
