@import "@/styles/globals.scss";




































































































































































































































































































































































































.buy-membership {
  .venue {
    display: none;
  }

  .previous-membership {
    margin-bottom: 32px;
  }

  .previous-membership .label {
    font-weight: bold;
  }
}
