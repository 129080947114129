@import "@/styles/globals.scss";







































































































.date-selector {
  .selected-date-time {
    margin-top: 16px;

    @include desktopExperience {
      max-width: calc(50% - 6px);
    }
  }

  .date-selector-list {
    padding: 0;
    margin: 1em 0;
    list-style-type: none;
    display: grid;
    gap: 12px;

    @include desktopExperience {
      grid-template-columns: 1fr 1fr;
    }

    .selectable-button {
      $gap: 7px;

      display: grid;
      grid-template-columns: 1fr auto;
      gap: $gap;
      align-items: center;

      .column {
        gap: $gap;
        display: flex;
        flex-direction: column;
        &:nth-child(2) {
          text-align: right;
          padding-top: 2px;
          gap: 5px;
        }
      }

      .price-with-prefix {
        display: flex;
        gap: 4px;
        align-items: baseline;
        justify-content: flex-end;

        > span {
          font-size: 11px;
        }
      }

      .date,
      .price {
        font-weight: 600;
      }
    }
  }
}
