@import "@/styles/globals.scss";

































































.login-or-guest-dialog {
  h3 {
    font-size: 22px;
    line-height: 24px;
    margin: 0 0 16px;
  }

  button {
    margin-bottom: 0;
  }

  // Override scoped css in the <LoginForm> component.
  .actions button.primary[class] {
    margin-bottom: 0;
  }

  @include smallAndMedium {
    > :first-child {
      padding-bottom: 32px;
      border-bottom: 1px solid var(--light-gray);
      margin-bottom: 32px;
    }
  }

  @include large {
    display: flex;
    > * {
      flex-basis: 50%;
      &:first-child {
        padding-right: 32px;
        border-right: 1px solid var(--light-gray);
      }

      &:last-child {
        padding-left: 32px;
      }
    }

    > * {
      display: flex;
      flex-direction: column;
      > :nth-child(2) {
        flex-grow: 1;
      }
    }
  }
}
