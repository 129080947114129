@import "@/styles/globals.scss";






















































.event-listing-item {
  display: grid;
  grid-auto-columns: auto 1fr;
  cursor: pointer;

  .button {
    height: fit-content;
    width: fit-content;
    margin-left: 0;
  }

  @include large {
    figure {
      grid-column: 1;
      grid-row: 1/20;
    }

    .title-link,
    .subtitle,
    .description,
    .venue,
    .button.primary {
      grid-column: 2;
    }
  }

  @include smallAndMedium {
    .figure,
    .title-link,
    .subtitle,
    .description,
    .venue,
    .button.primary {
      grid-column: 1;
    }
  }
}
