@import "@/styles/globals.scss";





















svg.calendar-icon {
  fill: var(--secondary-color);
}
