@import "@/styles/globals.scss";



































select.select-filter {
  width: auto;
}
