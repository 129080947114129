@import "@/styles/globals.scss";







































































































































.survey {
  margin-top: 32px;

  @include mobileExperience {
    margin-top: 24px;
  }
}
