@import "@/styles/globals.scss";


















































svg.document-icon {
  stroke: var(--secondary-color);
}
