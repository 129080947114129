@import "@/styles/globals.scss";











































































// TODO Reconcile the style
.reserve-mobile-footer,
.checkout-mobile-footer,
.find-seats-mobile-footer,
.find-available-tickets-mobile-footer {
  display: flex;
  align-items: center;

  .main-button-wrapper {
    width: 100%;
  }

  .cart-button {
    margin-right: 8px;
  }

  .checkout-button,
  .payment-button-wrapper {
    flex-grow: 1;
  }

  .payment-button-wrapper {
    .purchase {
      width: 100%;
      height: 48px;
    }
  }

  .total {
    text-align: right;
    font-size: 14px;
    line-height: 24px;
    margin-left: 8px;
    flex-shrink: 0;

    .price-wrapper {
      font-weight: 700;
      font-size: 18px;
    }
  }

  .submit {
    button {
      // Override inline style
      height: 100% !important;
      width: 100%;
    }
  }
}
