@import "@/styles/globals.scss";
























































































































































.pay-button-wrapper {
  height: 100%;

  > .purchase {
    height: 100%;
  }

  .gpay-card-info-container {
    min-width: 150px;
  }

  button {
    width: 100%;
    min-height: 48px;
  }
}
