@import "@/styles/globals.scss";

















































































$smaller: calc(1rem - 2px);

.remove-cart-item-overlay {
  font-size: $smaller;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .actions {
    display: inline-flex;
    flex-direction: row;
    gap: 0;

    > button:not(:last-child) {
      margin-right: 12px;
    }
  }

  .message {
    margin-top: 0;
  }

  .dependency-warning {
    color: var(--warning-color);
  }
}
