@import "@/styles/globals.scss";







































































































































































































































.named-members {
  select {
    width: 5em;
  }
}
