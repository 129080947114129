@import "@/styles/globals.scss";































































.apply-promo-codes-checkout {
  margin-bottom: 32px;

  @include desktopExperience {
    display: none;
  }

  h2 {
    margin-bottom: 16px;
  }

  .applied-codes {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 16px;
  }

  .applied-code {
    display: flex;
    align-items: center;
    background-color: #0000000a;
    padding: 8px 12px;
    width: fit-content;

    svg {
      margin-right: 10px;
    }
  }
}
