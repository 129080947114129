@import "@/styles/globals.scss";




















































































































































































.select-available-session {
  .sessions {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  .session {
    align-items: center;
  }

  &.has-prices.availability-visible {
    .session .availability-status {
      font-size: 0.75em;
    }
  }

  .session {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: calc(33% - 10px);

    @include desktopExperience {
      flex-basis: calc(16.66% - 10px);
    }

    label {
      cursor: inherit;
    }

    .session-time {
      display: block;
      font-weight: 600;
    }

    .row:not(:first-child) {
      margin-top: 7px;
    }

    &.sold-out {
      cursor: default;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .session-price {
      font-size: 0.88em;

      .from {
        font-size: 0.78em;
      }

      .price-wrapper {
        display: inline-block;
        margin-left: 2px;
      }
    }

    .badges {
      position: absolute;
      bottom: 6px;
      right: 6px;

      .badge {
        float: right;
      }
    }
  }

  &.has-prices {
    .session {
      flex-basis: calc(50% - 10px);

      @include desktopExperience {
        flex-basis: calc(25% - 10px);
      }
    }

    .session {
      min-height: 64px;
    }
  }

  .tabs {
    button {
      &.sold-out {
        .subtitle {
          color: var(--error-color);
        }
      }
    }
  }
}
