@import "@/styles/globals.scss";











































































$button-size: 38px;

.stepper {
  display: inline-flex;
  padding: 3px;
  white-space: nowrap;
  flex-shrink: 0;
  align-items: center;

  $button-size: 38px;

  > button {
    width: $button-size;
    height: $button-size;
    padding: 12px;
    border: 0 none;
    background: var(--primary-color);
    vertical-align: bottom;
    line-height: 0;

    > svg {
      vertical-align: middle;
      fill: white;
    }

    transition: opacity ease-out 50ms, background-color ease-out 50ms;

    &[disabled] {
      opacity: 0.2;
    }
  }

  > .count {
    width: $button-size;
    line-height: $button-size;
    display: inline-block;
    text-align: center;
  }
}
