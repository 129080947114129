@import "@/styles/globals.scss";













































































.mobile-footer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  padding: 16px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);

  @include mobileExperience {
    display: block;
  }
}
