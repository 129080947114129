@import "@/styles/globals.scss";






















svg.checkmark-icon {
  stroke: white;
}
