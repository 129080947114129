@import "@/styles/globals.scss";




































.dynamic-messages .message {
  margin-top: 16px;
  margin-bottom: 0;
}
