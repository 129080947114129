@import "@/styles/globals.scss";



























































































































































































































































































































.cancel-free-order {
  max-width: 750px;

  .order-form {
    margin-bottom: 32px;
    overflow: auto;
  }

  .title {
    margin-bottom: 32px;

    h2 {
      margin-top: 0;
    }
  }

  .session-table {
    border: 1px solid var(--medium-gray);
    margin-bottom: 24px;
  }

  .table-head {
    border-bottom: 1px solid var(--medium-gray);
    font-weight: bold;
    cursor: pointer;
    display: flex;

    .details {
      flex-grow: 1;
      padding: 12px 16px;

      > div {
        padding-bottom: 4px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .toggle-btn {
      border-left: 1px solid #cecece;
      width: 72px;
      margin: 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      svg {
        transform: rotate(180deg);
      }
    }

    @include large {
      padding: 16px 0;

      .details {
        display: flex;
        padding: 0 16px;

        > div {
          padding: 16px 8px;

          &.session-time,
          &.total-tickets {
            flex-basis: 15%;
            text-align: center;
          }

          &:last-child {
            padding-bottom: 8px;
          }
        }
      }

      .toggle-btn {
        margin: 0;
        width: 85px;
      }
    }

    .event-name {
      flex-grow: 1;
    }
  }

  .ticket-rows {
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s ease-out;
  }

  .open {
    .toggle-btn {
      svg {
        transform: none;
      }
    }

    .ticket-rows {
      display: block;
      max-height: 2500px;
      transition: all 1s ease-in;
    }
  }

  .session-table {
    .ticket-row {
      background-color: var(--light-gray);
      border-bottom: 1px solid var(--medium-gray);
      align-items: center;
      padding: 16px;
      display: flex;
      cursor: pointer;

      .label {
        order: 1;
        flex-grow: 1;
      }

      input[type='checkbox'] {
        order: 2;
        top: 0;

        &:not(:checked) {
          background-color: white;
        }
      }
    }

    .select-all label {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 16px;
      cursor: pointer;

      .label {
        order: 1;
        margin-right: 12px;
      }

      input[type='checkbox'] {
        order: 2;
        top: 0;
      }
    }
  }

  div.message.warning {
    margin-top: 32px;

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.3813 9.65407C18.7426 9.29284 18.7426 8.70716 18.3813 8.34593C18.0201 7.98469 17.4344 7.98469 17.0732 8.34593L10.3523 15.0669L7.65407 12.3687C7.29284 12.0074 6.70716 12.0074 6.34593 12.3687C5.98469 12.7299 5.98469 13.3156 6.34593 13.6768L9.6982 17.0291C10.0594 17.3903 10.6451 17.3903 11.0063 17.0291L18.3813 9.65407Z' fill='%23FFA500'/%3E%3C/svg%3E");
      background-color: transparent;
    }
  }

  .cancel-selected {
    width: 100%;
  }

  @include large {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin-bottom: 0;
      }

      button.cancel-order {
        align-self: center;
      }
    }
  }

  @include mediumAndLarge {
    .cancel-selected {
      float: right;
      width: auto;
    }
  }
}
