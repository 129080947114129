@import "@/styles/globals.scss";

























































































.unnamed-members {
  select {
    width: 5em;
  }
}
