@import "@/styles/globals.scss";



























svg.logout-icon {
  stroke: var(--secondary-color);
  fill: var(--secondary-color);
}
