@import "@/styles/globals.scss";

























































































.applied-card,
form.add-card-form .form-container {
  display: flex;
}

.applied-cards {
  .applied-card {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .code-wrapper {
      flex-basis: 50%;

      code {
        font-family: inherit;
      }
    }

    .balance-wrapper {
      margin: 0 16px;
    }

    button.remove {
      width: min-content;
      padding: 0;
      line-height: 48px;
    }
  }
}
